const ImageRenderedRoot = "/3dAssets/images/";
const UIRoot = "/3dAssets/UI/";
const assetsImagesFolder = "/assets/images/";

export const ImageString = {
  IntoCardLogo: assetsImagesFolder + "AnnualAwards.png",
  ChatClose: UIRoot + "chatClose.png",
  ChatButton: UIRoot + "chatIcon.png",
  PollButton: UIRoot + "poll.png",

  ExhibitionImage: ImageRenderedRoot + "exhibitionArea.jpg",
  Black: ImageRenderedRoot + "black.jpg",

  Star: UIRoot + "star.png",

  IntroLogin: assetsImagesFolder + "IntoLogo.png",
  LOGOR1: assetsImagesFolder + "R1Logo.png",
  LOGOKOA: assetsImagesFolder + "koaLogo.png",
  LOGOSTRATETHON: assetsImagesFolder + "logo.png", //STRATETHON.png',
  LOGOSEASON2: assetsImagesFolder + "SEASON2.png",

  LOGO: assetsImagesFolder + "logo.png",
  LOGOLG: assetsImagesFolder + "logo-lg.png",
  LoginScreen: assetsImagesFolder + "login/login.jpg",
  LOGINSCREENSIDELOGO: assetsImagesFolder + "logo.png",
  MENULOGO: assetsImagesFolder + "logo.png",

  LOBBYLOOP: ImageRenderedRoot + "Lobby.jpg",
  BACKLOOP: ImageRenderedRoot + "LobbyBack.jpg",

  Infodesk: ImageRenderedRoot + "Info.jpg",
  Game: ImageRenderedRoot + "Game.jpg",
  LOBBYBACKWINNERS: ImageRenderedRoot + "LOBBYBACKWINNERS.jpg",
  LOBBYBACKAWARDS: ImageRenderedRoot + "LOBBYBACKAWARDS.jpg",
  PhotoBooth: ImageRenderedRoot + "Photobooth.jpg",
  AGENDA: ImageRenderedRoot + "toAgenda.jpg",
  Audi: ImageRenderedRoot + "Audi.jpg",
  WorkShop: ImageRenderedRoot + "work_shop.jpg",

  Room1Image: ImageRenderedRoot + "w1/Audi.jpg",
  Room2Image: ImageRenderedRoot + "w2/Audi.jpg",
  Room3Image: ImageRenderedRoot + "w3/Audi.jpg",
  Room4Image: ImageRenderedRoot + "w4/Audi.jpg",

  ProductStall: ImageRenderedRoot + "Product-Stall.jpg",
  Breakout: ImageRenderedRoot + "breakout.jpg",
  BusLeft: ImageRenderedRoot + "BusLeft.png",

  WELCOMEBACKGROUND: "assets/images/bg/all-stages.jpg",

  RIPPLE: "/3dAssets/gifs/Ripple-2.4s-128px.svg",
  //UI
  BACKBUTTON: UIRoot + "backButton.png",
  CLOSEBUTTON: UIRoot + "closeButton.png",
  // CLOSEBUTTON: UIRoot + "backButton.png",

  CLOSEBUTTON2: UIRoot + "closeButton-2.png",

  CROSS: UIRoot + "cross.png",
  BUMPERPRIZEHEADER: "assets/images/banner-header.jpg",
  PDFLOGO: UIRoot + "pdfIcon.png",
  INFOBUTTON: UIRoot + "info/information.png",

  LIVEINDICATOR: UIRoot + "eye.png",
  MODIFACE: ImageRenderedRoot + "Modi-face.jpg",
  DIVISION: ImageRenderedRoot + "Division.jpg",
};

export const StallImages = {
  stall1: ImageRenderedRoot + "stalls/stall1.jpg",
};
