export const AppString = {
  QNA: "qna-bd",
  QNAREPLY: "qnaReply",
  PollAdmin_Col: "pollAdmin",
  Poll_Doc: "poll",
  PollAdmin_Doc: "adminAccess",
  Dailyco_Doc: "",
  Dailyco_Col: "dailyco-bd",

  BACKSATGE: "backStage",
  BACKSTAGELINK: "staticData",
  BACKSTAGEREALTIMEDATA: "realtimeData",

  SPECIALUSER: "specialUser",

  CONNECT: "connect",
  UserScore: "cricketScore",
  USERS_COLLECTION: "users",

  REALTIME: "realtime",
};
