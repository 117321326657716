import React, { Component } from "react";
import { HotspotType } from "../../../const/fixed/Types";
import UserContext from "../../auth/providers/UserContext";
import { AppString } from "../../firebase/AppString";
import {
  addLogoutAnalytics,
  firestore,
  getUserScore,
  signOut,
} from "../../firebase/firebase";
const starStyle = {
  background: "url(/assets/images/leaderStart.png)",
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
};
class MyProfile extends Component {
  state = {
    score: 0,
    userData: window.userData,
  };

  componentDidMount = async () => {
    window.pro = this;
    console.log(window.userData);
    // let user = {
    //   name: this.context.displayName ? this.context.displayName : "User",
    //   email: this.context.email ? this.context.email : "userEmail@example.com",
    // };
    // this.setState({ userData: user });
  };

  getUserdata = (phoneNumber) => {
    return new Promise(async (resolve, reject) => {
      try {
        const docRef = firestore
          .collection(AppString.USERS_COLLECTION)
          .where("phoneNumber", "==", `${phoneNumber}`);
        const query = await docRef.get();
        if (query.empty) {
          let error = {
            code: "UserNotFound",
            message: "User is not registered",
          };
          throw error;
        }
        resolve(query.docs[0].data());
      } catch (err) {
        reject(err);
      }
    });
  };

  logout = (event) => {
    event.preventDefault();
    addLogoutAnalytics(this.context).then(() => {
      signOut();
    });
  };

  handleButtonClick = () => {
    this.props.ShowMediaModal(
      HotspotType.pdf,
      "/web/viewer.html?file=%2Fassets%2Fcontent%2Fleaderboard.pdf"
    );
  };

  render() {
    // console.log(this.context);
    return (
      <>
        <div className="user-profile">
          <div className="user-profile__header ">
            <a
              href="#"
              className="user-profile__close"
              onClick={(e) => this.props.close(e)}
            >
              <img
                src={process.env.PUBLIC_URL + "/3dAssets/UI/chatClose.png"}
                alt=""
              />
              {/* <i className="icon-close"></i> */}
            </a>
          </div>
          <div className="user-profile__body">
            <div className="user-profile__form">
              <div className="form-group">
                <label className="form-label">Name</label>
                <div className="form-group__has-icon">
                  <input
                    className="form-control"
                    value={this.state.userData ? this.state.userData.name : ""}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Email id</label>
                <div className="form-group__has-icon">
                  <input
                    className="form-control"
                    value={this.state.userData ? this.state.userData.email : ""}
                    readOnly={true}
                  />
                </div>
              </div>

              {/* <div className="form-group">
                <label className="form-label">Mobile Number</label>
                <div className="form-group__has-icon">
                  <input
                    className="form-control"
                    value={this.state.userData.mobile}
                    readOnly={true}
                  />
                </div>
              </div> */}

              <div className="form-group">
                <label className="form-label">College/Organization</label>
                <div className="form-group__has-icon">
                  <input
                    className="form-control"
                    value={this.state.userData.college}
                    readOnly={true}
                  />
                </div>
              </div>

              {/* <div className="form-group">
                <label className="form-label">Morning Workshop</label>
                <div className="form-group__has-icon">
                  <input
                    className="form-control"
                    value={
                      this.props.allData.morningWorkshop
                        ? this.props.allData.morningWorkshop
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div> */}
              {/* <div className="form-group">
                <label className="form-label">Afternoon Workshop</label>
                <div className="form-group__has-icon">
                  <input
                    className="form-control"
                    value={
                      this.props.allData.afternoonWorkshop
                        ? this.props.allData.afternoonWorkshop
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div> */}
              {/* <div className="profile-score">
                <div>
                  Your <br></br>Score
                </div>
                <div className="value">{this.state.score}</div>
              </div> */}
            </div>
            <div className="scorecard__rank"></div>
          </div>
          <div className="user-profile__footer">
            <a
              href="#"
              className="btn btn-sm btn-red"
              onClick={(e) => this.logout(e)}
            >
              Log Out
            </a>
          </div>
        </div>
      </>
    );
  }
}
MyProfile.contextType = UserContext;
export default MyProfile;
