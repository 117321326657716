import React, { createContext, useEffect, useState } from "react";
import { LobbyHotspots } from "../data/Lobby/hotspots";
import { InfoHotspots_AR } from "../data/Lobby/hotspots_arabic";
export const LanguageContext = createContext();

const data = {
  english: {
    name: "English",
    InfoDeskProgramPdfLink: "/assets/content/2. Program.pdf",
    InfoDeskFAQPdfLink: "/assets/content/FAQs.pdf",
  },
  arabic: {
    name: "Arabic",
    InfoDeskProgramPdfLink: "/assets/content/FAQs.pdf",
    InfoDeskFAQPdfLink: "/assets/content/FAQs.pdf",
  },
  french: {
    name: "French",
    InfoDeskProgramPdfLink: "/assets/content/2. Program.pdf",
    InfoDeskFAQPdfLink: "/assets/content/FAQs.pdf",
  },
};
export function LanguageContextProvider(props) {
  const [selectedLanguage, setLanguage] = useState("english");
  const [languageData, setLanguageData] = useState({});
  const [hotspot, setHotspot] = useState(LobbyHotspots);
  useEffect(() => {
    // setLanguageData(data[`${selectedLanguage}`]);
    switch (selectedLanguage) {
      case "english":
        setHotspot(LobbyHotspots);
        console.log("in english");
        break;
      case "arabic":
        let currentHotspot = {
          ...LobbyHotspots,
          Infodesk: {
            ...LobbyHotspots.Infodesk,
            hotspot: {
              ...InfoHotspots_AR,
            },
            changed: true,
          },
        };
        setHotspot(currentHotspot);
        console.log("in arabic");
        break;
      case "french":
        setHotspot(LobbyHotspots);
        console.log("in french");
        break;
    }
  }, [selectedLanguage]);
  return (
    <LanguageContext.Provider
      value={{ selectedLanguage, setLanguage, languageData, hotspot }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
}
