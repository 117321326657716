import React, { Component } from "react";
import { UserContext } from "../auth/providers";
//#region package Import
import swal from "sweetalert";
import ReactPlayer from "react-player";
import AudiQNA from "../AudiQNA/App1";
import clapSound from "./clapping.mp3";
import AudiPoll from "../AudiPoll/PublicPoll";
import cut from "./cut.svg";
//#endregion
import FullScreenAudi from "../common/AudiScene/FullScreenAudi";
//#region importing components
import Menu from "./Menu/Menu";
import MyProfile from "./MyProfile/MyProfile";
import Scene from "../common/Scene/Scene";
import QNA from "../Qna/App1";
import PublicPoll from "../Poll/PublicPoll";
//#endregion
import Spinner from "./spinner.gif";
//#region Const import
import { AiFillHeart, AiFillLike } from "react-icons/ai";
import { FaHandPaper } from "react-icons/fa";
import {
  AnalyticsLocations,
  HotspotType,
  PointType,
  PointValues,
  workshopsName,
} from "../../const/fixed/Types";
import {
  AudiData,
  LobbyHotspots,
  MeetTheHeroHotspot,
  NetworkingLounge,
  ResourceCenterHotspot,
  TeamsData,
  BreakoutRoomHotspot,
  InfoHotspots,
  ExploreData,
  imagesData,
  DiviSionBoothHotspost,
  LobbyBackHotspots,
} from "../../data/Lobby/hotspots";
import {
  AudisubMenuId,
  GalleryIndex,
  GalleryItems,
  menuItems,
  menuItemsId,
  menuItemsIndex,
  NetworkingSubmenuId,
  workshopMenuItem,
  WallOfFameItems,
} from "../../const/Menu/MenuConst";
import { VideoString } from "../../const/assets/VideoString";
import { ImageString } from "../../const/assets/ImageString";
import VideoPlayer from "../common/videoPlayer/VideoPlayer";
import {
  PDFPlayerData,
  PDFPlayerDataSec,
  VideoPlayerData,
} from "../../data/Lobby/realtimeData";
import AudiScene from "../common/AudiScene/AudiScene";
import {
  decreaseLocationCount,
  firestore,
  updateUserLocation,
  addRealtimeHotspotAnalytics,
  addScore,
  getAudiLinkListener,
  removeAudiLinkListener,
  getLeaderboardData,
  getBreakoutRoomListener,
  getQnaZoneLinkListener,
  firebaseApp,
  getUserDetailsNew,
  addUserScore,
} from "../firebase/firebase";
import LobbyTut from "./Tut/LobbyTut";
import ReactAudioPlayer from "react-audio-player";
import CustomNotification from "../common/CustomNotification/CustomNotification";
import AudiTut from "./Tut/AudiTut";
import NetworkingTut from "./Tut/NetworkingTut";
import Connect from "../Connect/Connect";
import ClientChat from "../client-chat/ClientChat";
// import ReactTooltip from "react-tooltip";
// import NotLive from "../NotLive/NotLive";
// import ListContainer from "./ListContainer/ListContainer";
// import Leaderboard from "../Leaderboard/Leaderboard";
import PdfPlayer from "../common/PdfPlayer/PdfPlayer";
import Intro from "../auth/login/Intro";
import {
  isIOS,
  isIPad13,
  isMobileOnly,
  isMobileSafari,
} from "react-device-detect";
//#endregion
import Scorecard from "../Leaderboard/Scorecard";
import { AppString } from "../firebase/AppString";
import BreakoutMenu from "../breakoutMenu/BreakoutMenu";
import Lottie from "react-lottie-player";
import ImageGallery from "../ImageGallery/ImageGallery";
const videoRatios = 2.33;

class Home extends Component {
  state = {
    canShowRotate: true,
    currenLocation: "",
    showVideoLoaders: true,
    pdfPlayerData: {},
    pdfPlayerTitle: "BeautyTech",
    showCloseMediaModal: true,
    currentHotspot: "",
    restrictedZones: {},
    showClosedPopup: false,
    playlikeLottie: false,
    playheartLottie: false,
    playClapLottie: false,
    wk: "",
    likeCount: 0,
    heartCount: 0,
    clapCount: 0,
    show_scroll: false,
    change: false,
    audio: true,
    allData: {},
    chatID: "ITS_Support",
    scoreCard__Data: [],
    extra_user_details: {},
    BreakoutRoomHotspot: BreakoutRoomHotspot,
    UI: {
      menuItems: menuItems,
      activeMenu: menuItems[0],
      activeSubMenu: null,
      lastMenu: menuItems[0],
      overlayMenu: null,
      MenuVisible: true,
      isInteractable: false,
      showMediaModal: false,
      clicked: false,
      showHelpdeskChat: false,
      showLeaderboard: false,
      showLeaderboardButton: true,
      showIntro: this.props.canShowIntro,
      // showIntro: false,
      showComments: false,
      hideCommentsBox: false,
      hideCommentsBtn: false,
      showPoll: false,
    },
    HelpdeskChat: {
      showChat: true,
      showButton: false,
    },
    MediaModalInfo: {
      link: "",
      type: null,
    },
    audiLnk: AudiData.link,
    qna_zone_link: ExploreData.link,
  };
  currentOverlayCallback = null;
  currentAudioRef = React.createRef();
  AudioListenerRemoved = false;
  clapref = React.createRef();
  closeCommentBox = (event) => {
    if (event) event.preventDefault();

    this.setState((prev) => ({
      UI: {
        ...prev.UI,
        hideCommentsBox: true,
      },
    }));

    setTimeout(() => {
      this.setState((prev) => ({
        UI: {
          ...prev.UI,
          showComments: false,
          hideCommentsBox: false,
          hideCommentsBtn: false,
          showPoll: false,
        },
      }));
    }, 300);
  };
  closePoll = (event) => {
    if (event) event.preventDefault();

    this.setState((prev) => ({
      UI: {
        ...prev.UI,
        hideCommentsBox: true,
      },
    }));
    setTimeout(() => {
      this.setState((prev) => ({
        UI: {
          ...prev.UI,
          showComments: false,
          hideCommentsBox: false,
          hideCommentsBtn: false,
          showPoll: false,
        },
      }));
    }, 300);
  };
  componentDidUpdate = () => {};

  restrictionZone = () => {
    firestore
      .collection(AppString.BACKSATGE)
      .doc("restrictions")
      .onSnapshot((doc) => {
        if (doc.exists) {
          this.setState({ restrictedZones: doc.data() });
        }
      });
  };
  forcePushToZones = () => {
    firestore
      .collection(AppString.BACKSATGE)
      .doc("forcePush")
      .onSnapshot((doc) => {
        if (doc.exists) {
          console.log(doc.data().zone);
          switch (doc.data().zone) {
            case 0:
              this.handleClick(null, menuItems[menuItemsIndex.Lobby]);
              break;
            case 1:
              this.handleClick(null, menuItems[menuItemsIndex.Audi]);
              break;
            case 2:
              this.handleClick(null, menuItems[menuItemsIndex.BreakoutRoom]);
              break;
            case 3:
              this.handleClick(null, menuItems[menuItemsIndex.Explore]);
              break;
            default:
              return;
          }
        }
      });
  };
  componentDidMount = async () => {
    window.c = this;
    this.restrictionZone();
    this.forcePushToZones();
    window.userData = await getUserDetailsNew(this.context.email);
    window.parent.toggleCloseMediaModal = this.toggleCloseMediaModal;
    firestore
      .collection(AppString.BACKSATGE)
      .doc("totalLikes")
      .onSnapshot((doc) => {
        if (doc.exists) {
          this.setState({ likeCount: doc.data().count });
        }
      });
    firestore
      .collection(AppString.BACKSATGE)
      .doc("totalHearts")
      .onSnapshot((doc) => {
        if (doc.exists) {
          this.setState({ heartCount: doc.data().count });
        }
      });
    firestore
      .collection(AppString.BACKSATGE)
      .doc("totalClaps")
      .onSnapshot((doc) => {
        if (doc.exists) {
          this.setState({ clapCount: doc.data().count });
        }
      });
    console.log(this.props.languageContext);
    getBreakoutRoomListener((err, hotspot) => {
      if (err) {
        console.log(err);
        return;
      }
      let newObj = {};
      Object.keys(this.state.BreakoutRoomHotspot).forEach((key) => {
        newObj = {
          ...newObj,
          [`${key}`]: {
            ...this.state.BreakoutRoomHotspot[`${key}`],
            ...hotspot[`${key}`],
          },
        };
      });
      // console.log(newObj);
      this.setState({ BreakoutRoomHotspot: newObj });
    });
    getAudiLinkListener((err, link) => {
      if (err) {
        console.log(err);
        return;
      }
      this.setState({
        audiLnk: link,
      });
    });
    getQnaZoneLinkListener((err, link) => {
      if (err) {
        console.log(err);
        return;
      }
      console.log(link);
      this.setState({
        qna_zone_link: link,
      });
    });
    window.addEventListener("click", this.playIntroAudio);
    window.parent.updateScore = this.updateScore;
    getLeaderboardData((nullres, res) => {
      this.setState({ scoreCard__Data: res });
    });
    console.log(this.context.email);
  };

  updateScore = async (game, score) => {
    await addUserScore(this.context, 1, game, score);
  };

  componentWillUnmount() {
    if (!this.AudioListenerRemoved) {
      this.AudioListenerRemoved = true;
      window.removeEventListener("click", this.playIntroAudio);
    }
    removeAudiLinkListener();
  }

  playIntroAudio = () => {
    // console.log(this.currentAudioRef)
    if (this.currentAudioRef.current)
      this.currentAudioRef.current.audioEl.current.play();
    this.AudioListenerRemoved = true;
    window.removeEventListener("click", this.playIntroAudio);
  };

  isMediaHotspot = () => {
    if (
      this.state.MediaModalInfo.type === HotspotType.video ||
      this.state.MediaModalInfo.type === HotspotType.pdf ||
      this.state.MediaModalInfo.type === HotspotType.qrCode
    ) {
      return true;
    } else return false;
  };

  //#region UI Function
  showInfoPopUp(message) {
    swal({
      title: message,
      icon: "info",
      className: "video-swal-modal",
      button: "continue",
    });
  }

  openLastMenu = (event) => {
    if (event) event.preventDefault();
    this.setState((state) => ({
      UI: { ...state.UI, activeMenu: state.UI.lastMenu, overlayMenu: null },
    }));
  };

  handleClick = (event, item, overlayCloseCallback) => {
    if (event) event.preventDefault();
    this.closeMediaModal();
    this.setState({
      playlikeLottie: false,
      playClapLottie: false,
      playheartLottie: false,
    });
    // console.log(item);

    if (item.name === "IDEA WALL") {
      this.setState({ currenLocation: item.name }, () => {
        updateUserLocation(this.context, item.name);
      });
    }

    if (item.id === menuItemsId.Audi && this.state.restrictedZones.Audi) {
      this.setState({ showClosedPopup: true });
      return;
    }

    if (item.id === menuItemsId.TeamBuilding) {
      this.showMediaModal(
        HotspotType.iframe,
        "/photo-axis/noteCloud/index.html"
      );

      this.addHotspotAnalytics("ideaWall");
      return;
    }

    if (
      item.id === menuItemsId.BreakoutRoom &&
      this.state.restrictedZones.Breakout
    ) {
      this.setState({ showClosedPopup: true });
      return;
    }
    if (item.id === menuItemsId.Explore && this.state.restrictedZones.QnaZone) {
      this.setState({ showClosedPopup: true });
      return;
    }
    // if (!window.isWkAdmin) {
    //   if (item.id === menuItemsId.BreakoutRoom) {
    //     this.handleClick(event, workshopMenuItem);
    //     return;
    //   }
    // }
    this.setState((prev) => ({
      UI: {
        ...prev.UI,
        showComments: false,
        hideCommentsBox: false,
        hideCommentsBtn: false,
        showPoll: false,
      },
    }));
    if (item.id === "Workshop") {
      this.setState((state) => ({
        UI: {
          ...state.UI,
          activeMenu: item,
          overlayMenu: null,
          lastMenu: state.UI.activeMenu,
          activeSubMenu: null,
        },
      }));
      return;
    }
    if (this.state.UI.overlayMenu) {
      if (item.id === this.state.UI.overlayMenu.id) {
        return;
      }
    } else if (item.id === this.state.UI.activeMenu.id) {
      return;
    }

    if (overlayCloseCallback) {
      this.currentOverlayCallback = overlayCloseCallback;
    }

    if (
      item.id !== menuItemsId.MyProfile &&
      item.id !== menuItemsId.TeamBuilding
    ) {
      this.handleHelpdeskChatClose();
      this.setState((state) => ({
        UI: {
          ...state.UI,
          activeMenu: item,
          overlayMenu: null,
          lastMenu: state.UI.activeMenu,
          activeSubMenu: null,
          showHelpdeskChat: false,
          showLeaderboard: false,
          showLeaderboardButton: true,
        },
      }));
    } else {
      this.setState((state) => ({
        UI: { ...state.UI, overlayMenu: item, lastMenu: state.UI.activeMenu },
      }));
    }
  };

  handleSubmenuItemClick = (event, item) => {
    if (event) event.preventDefault();
    if (this.state.UI.activeSubMenu)
      if (this.state.UI.activeSubMenu.id === item.id) {
        return;
      }

    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        activeSubMenu: item,
      },
    }));

    if (this.state.UI.activeMenu.id === menuItemsId.Networking) {
      this.addHotspotAnalytics(item.name);
    }
  };

  closeSubMenu = (event) => {
    if (event) event.preventDefault();
    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        activeSubMenu: null,
        showMediaModal: false,
      },
      MediaModalInfo: {
        ...prevState.MediaModalInfo,
        link: "",
        type: null,
      },
    }));
  };

  hideOverlayMenu = (event) => {
    if (event) event.preventDefault();
    if (this.state.UI.overlayMenu) {
      this.setState((state) => ({
        UI: { ...state.UI, overlayMenu: null, showAskExpertError: false },
      }));
    }
    if (this.currentOverlayCallback) {
      this.currentOverlayCallback();
      this.currentOverlayCallback = null;
    }
  };

  showMediaModal = (hotspotType, mediaLink, qrLink = null) => {
    if (hotspotType === HotspotType.video) {
      if (this.currentAudioRef.current) {
        this.currentAudioRef.current.audioEl.current.pause();
      }
    }
    if (hotspotType === HotspotType.modiFace) {
      window.open(mediaLink);
      console.log(mediaLink, qrLink);
      this.showMediaModal(HotspotType.qrCode, qrLink);
      return;
    }
    if (hotspotType === HotspotType.chatbot) {
      window.lastChatBotRef = mediaLink;
      mediaLink.enabled = false;
      this.handleHelpdeskChatbutton();
      return;
    }
    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        showMediaModal: true,
      },
      MediaModalInfo: {
        ...prevState.MediaModalInfo,
        link: mediaLink ? mediaLink : "",
        type: hotspotType,
      },
    }));
  };

  handleMedialModalFromOverlay = (hotspotType, link) => {
    this.hideOverlayMenu();
    this.showMediaModal(hotspotType, link);
  };

  closeMediaModal = () => {
    if (this.currentAudioRef.current) {
      this.currentAudioRef.current.audioEl.current.play();
    }

    var games_arr = ["Cricket", "Quiz"];
    if (games_arr.includes(this.state.currenLocation)) {
      updateUserLocation(this.context, "Games");
    }

    var lobbyArr = ["Agenda", "IDEA WALL"];
    if (lobbyArr.includes(this.state.currenLocation)) {
      updateUserLocation(this.context, "lobby");
    }

    var backWallPeople = [
      "TheUltimateWinner",
      "TheUltimateJuryChoice",
      "TheUltimateRunnerUp",
      "TheUltimatePopularChoice",
    ];
    if (backWallPeople.includes(this.state.currenLocation)) {
      updateUserLocation(this.context, "wallOfFamePeople");
    }

    this.props.toggleRotateScreen(true);
    this.setState((prevState) => ({
      show_scroll: false,
      showVideoLoaders: true,
      canShowRotate: true,
      UI: {
        ...prevState.UI,
        showMediaModal: false,
      },
      MediaModalInfo: {
        ...prevState.MediaModalInfo,
        link: "",
        type: null,
      },
    }));
  };
  //#endregion

  handleTeamOptionSelection = (event, teams) => {
    // event.preventDefault();
    window.open(teams.link, "_blank");
    this.hideOverlayMenu(event);
  };

  checkIfAudioCanPlayUnderMediaModal = (val, canPrevent) => {
    if (!val && sessionStorage.getItem("Lobby-tut")) {
      // this.setState({ audio: false });
      return true;
    }
    if (!val && !sessionStorage.getItem("Lobby-tut")) {
      // this.setState({ audio: false });
      return false;
    }
    if (val) {
      this.setState({ audio: false });
      return true;
    }
    if (canPrevent) {
      if (this.state.UI.activeMenu.id === menuItemsId.Lobby) {
        return false;
      }
    }
    if (sessionStorage.getItem("Lobby-tut")) {
      if (this.state.UI.activeMenu.id === menuItemsId.Lobby) {
        return true;
      }
    }

    if (this.state.UI.activeMenu.id === menuItemsId.Networking) {
      if (this.state.UI.activeSubMenu)
        if (this.state.UI.activeSubMenu.id === NetworkingSubmenuId.Sessions) {
          return false;
        }
    }
    if (this.state.UI.showMediaModal) {
      if (this.state.MediaModalInfo.type === HotspotType.pdf) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  handleHelpdeskChatClose = () => {
    if (window.lastChatBotRef) window.lastChatBotRef.enabled = true;
    if (this.state.HelpdeskChat.showChat) {
      this.setState({
        HelpdeskChat: {
          showChat: false,
          showButton: true,
        },
      });
    }
  };

  handleHelpdeskChatbutton = () => {
    if (this.state.HelpdeskChat.showButton) {
      this.setState({
        HelpdeskChat: {
          showChat: true,
          showButton: false,
        },
      });
    }
  };

  handleLobbyInternalState = (internalState) => {
    switch (internalState) {
      case "Infodesk":
        this.setState((prevState) => ({
          UI: {
            ...prevState.UI,
            showHelpdeskChat: true,
          },
          HelpdeskChat: {
            showChat: false,
            showButton: true,
          },
        }));
        break;
      default:
        this.setState((prevState) => ({
          UI: {
            ...prevState.UI,
            showHelpdeskChat: false,
          },
          HelpdeskChat: {
            showChat: true,
            showButton: true,
          },
        }));
    }
  };

  handleSceneTutorial = (type) => {
    if (type === "end") {
      this.setState((prevState) => ({
        UI: {
          ...prevState.UI,
          isInteractable: true,
        },
      }));
    }
  };
  toggleCloseMediaModal = (value) => {
    this.setState({ showCloseMediaModal: value });
  };
  //#region Analytics
  handleHotspotAnalytics = (hotspotDetails) => {
    console.log(hotspotDetails.id);

    this.setState({ currenLocation: hotspotDetails.id }, () => {
      var locationArr = [
        "Agenda",
        "Photobooth",
        "Games",
        "Cricket",
        "Quiz",
        "wallOfFamePeople",
        "TheUltimateWinner",
        "TheUltimateJuryChoice",
        "TheUltimateRunnerUp",
        "TheUltimatePopularChoice",
        "campusAwards",
        "humanResources",
        "generalManagement",
        "finance",
        "operations",
      ];
      if (locationArr.includes(this.state.currenLocation)) {
        updateUserLocation(this.context, hotspotDetails.id);
      }
    });

    if (hotspotDetails.id === "Photomosaic") {
      this.setState({ show_scroll: true });
    }
    if (hotspotDetails.id === "Word_Hunt") {
      this.props.toggleRotateScreen(false);
    }
    if (hotspotDetails.id === "General_Support") {
      this.setState({ chatID: "General_Support" });
    }
    if (hotspotDetails.id === "ITS_Support") {
      this.setState({ chatID: "ITS_Support" });
    }
    if (hotspotDetails.id === "wordHunt") {
      this.setState({ canShowRotate: false });
    }
    this.addHotspotAnalytics(hotspotDetails.id);
  };

  addComponentAnalytics = (location, value) => {
    //add to user
    console.log("workshop clck");

    if (value) this.addHotspotAnalytics(location);
    //add to current status)
    if (location !== AnalyticsLocations.Teambuilding)
      this.addLocationAnalytics(location, value);

    this.setState({ currenLocation: location }, () => {
      var locationArr = [
        "workshop-1",
        "workshop-2",
        "workshop-3",
        "workshop-4",
      ];
      if (locationArr.includes(this.state.currenLocation)) {
        updateUserLocation(this.context, workshopsName[location]);
      }
    });
  };

  addLocationAnalytics = (location, value) => {
    if (value) {
      // add
      if (location) {
        updateUserLocation(this.context, location);
      }
    } else {
      // remove
    }
  };

  addHotspotAnalytics = (name) => {
    //call some firebase function
    if (name === "BeautyTech") {
      this.setState({
        pdfPlayerData: PDFPlayerData,
        pdfPlayerTitle: "BeautyTech",
      });
    } else if (name === "Your L'Oréal Journey") {
      this.setState({
        pdfPlayerData: PDFPlayerDataSec,
        pdfPlayerTitle: "Your L'Oréal Journey",
      });
    }
    this.setState({ currentHotspot: name });
    addRealtimeHotspotAnalytics(this.context, name);
  };
  //#endregion

  //#region LeaderBoard
  addScorePoints = (point) => {
    console.log("pooint->" + point);
    addScore(this.context, point);
  };
  handleLeaderboardClose = () => {
    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        showLeaderboard: false,
        showLeaderboardButton: true,
      },
    }));
  };
  handleLeaderboardButton = () => {
    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        showLeaderboard: true,
        showLeaderboardButton: false,
      },
    }));
  };
  adminAccess = (data) => {
    this.setState({ wk: data });
  };
  // handleMedialModalFromOverlay
  canShowLeaderboardInNet = () => {
    if (this.state.UI.activeMenu.id === menuItemsId.Networking) {
      if (this.state.UI.activeSubMenu) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  //#endregion

  closeIntro = () => {
    this.setState((prevState) => ({
      UI: {
        ...prevState.UI,
        showIntro: false,
      },
    }));
    sessionStorage.setItem("IntoPlayed", "true");
  };
  stateChange = () => {
    this.setState({ change: true });
  };
  addScore = async (user, scorename, item) => {
    console.log(user, scorename, item);

    const userDocRef = firestore.collection("userScore").doc(user);
    const result = await firestore.runTransaction(async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      let userScore = {
        id: user,
        scores: {},
        total: 0,
        fullname: this.state.extra_user_details.fullname,
        businessAarea: this.state.extra_user_details.businessAarea,
      };
      if (userDoc.exists) {
        userScore = {
          ...userScore,
          ...userDoc.data(),
        };
      }
      if (userScore.scores.hasOwnProperty(scorename) && !item.oneTime) {
        userScore.scores[scorename] += item.value;
      } else {
        userScore.scores[scorename] = item.value;
      }

      let newTotal = 0;
      Object.keys(userScore.scores).forEach((gameName) => {
        newTotal += userScore.scores[gameName];
      });

      if (newTotal > userScore.total) {
        userScore.total = newTotal;
        userDoc.exists
          ? transaction.update(userDocRef, userScore)
          : transaction.set(userDocRef, userScore);
      } else {
        let err = {
          code: "TotalScoreLess",
          message: "Earlier Total Score was higher",
        };
        throw err;
      }
    });
  };
  hideLanguageToggle = () => {
    this.setState({ currentHotspot: "" });
  };
  setResPopup = () => {
    this.setState({ showClosedPopup: true });
  };
  updatesLikeClapsHearts = async (doc) => {
    await firestore
      .collection(AppString.BACKSATGE)
      .doc(doc)
      .update({ count: firebaseApp.firestore.FieldValue.increment(1) });
  };
  setCurrentWorkshopForAdmin = (key) => {
    this.setState({ wk: key }, () => {
      this.handleClick(
        null,
        this.state.BreakoutRoomHotspot[this.state.wk].newItem
      );
    });
  };
  handlePollForceOpen = (menuIndex, subMenuId) => {
    const menu = menuItems[menuIndex];
    const item = menu.subMenus.filter((el) => el.id === subMenuId)[0];
    this.handleSubmenuItemClick(null, item);
  };
  render() {
    return (
      <>
        {/* {(this.state.currentHotspot === "Infodesk" ||
          this.state.currentHotspot === "BusLeft" ||
          this.state.currentHotspot === "BusRight" ||
          this.state.currentHotspot === AnalyticsLocations.ProductBooth) && (
          <div className="languageDiv">
            <p>Language: </p>
            <select
              name="language-selection"
              id="language-selection"
              value={this.props.languageContext.selectedLanguage}
              onChange={(e) => {
                this.props.languageContext.setLanguage(e.target.value);
              }}
            >
              <option value="english">English</option>
              <option value="arabic">عربى</option>
              <option value="french">Français</option>
            </select>
          </div>
        )} */}
        {this.state.UI.showIntro && <Intro close={this.closeIntro}></Intro>}
        {this.state.show_scroll && (
          <div className="scroll__container">
            <span className="scroll__arrow">{">"}</span>
          </div>
        )}
        {!this.state.UI.showIntro && (
          <>
            <div className="wrapper">
              {this.state.showClosedPopup && (
                <div className="closedPopup">
                  <div>
                    <img
                      src={cut}
                      alt=""
                      onClick={() => {
                        this.setState({ showClosedPopup: false });
                      }}
                    />
                    <h2>Currently the zone is restricted</h2>
                  </div>
                </div>
              )}
              <section
                className={`loggedin-page has-right-menu min-height-full min-height-full image-bg   ${
                  isIOS ? "ios-page" : ""
                } ${isIPad13 ? "on-ipad" : ""} ${
                  window.parent.ios_V
                    ? window.parent.ios_V === 15
                      ? "ios-15"
                      : ""
                    : ""
                } ${
                  window.parent.ios_V
                    ? window.parent.ios_V === 15 && !isMobileSafari
                      ? "ios-15-ch"
                      : ""
                    : ""
                } ${
                  window.parent.ios_V2
                    ? window.Number(window.parent.ios_V2) >=
                      window.Number("14.2")
                      ? "ios-14-2"
                      : ""
                    : ""
                }`}
                style={{
                  marginRight:
                    this.state.UI.activeMenu.id === menuItemsId.Audi ? "" : "",
                }}
              >
                <CustomNotification firestore={firestore}></CustomNotification>
                {this.state.UI.showMediaModal && (
                  <>
                    <article
                      className={`img-bg videoBox h-100 zIndex-16`}
                      id="play"
                    >
                      <div
                        className="media-modal"
                        onClick={this.closeMediaModal}
                      >
                        {this.state.MediaModalInfo.type ===
                          HotspotType.pdfPlayer && (
                          <PdfPlayer
                            title={this.state.pdfPlayerTitle}
                            data={this.state.pdfPlayerData}
                            close={() => this.closeMediaModal()}
                          ></PdfPlayer>
                        )}
                        {this.state.MediaModalInfo.type ===
                          HotspotType.videoPlayer && (
                          <VideoPlayer
                            videoData={VideoPlayerData}
                            close={() => this.closeMediaModal()}
                          ></VideoPlayer>
                        )}
                        {this.state.MediaModalInfo.type === HotspotType.pdf && (
                          <iframe
                            title={"pdf"}
                            className="media-modal-content"
                            src={this.state.MediaModalInfo.link}
                          ></iframe>
                        )}
                        {this.state.MediaModalInfo.type ===
                          HotspotType.iframe && (
                          <iframe
                            title={"iframe"}
                            allow="camera *;microphone *"
                            className={`media-modal-content-iframe  ${
                              isIOS ? "ios-media-modal-content-iframe" : ""
                            }`}
                            style={
                              this.state.MediaModalInfo.link ===
                              "/photo-axis/reliancePhotobooth/index.html"
                                ? isMobileOnly
                                  ? {
                                      width: "70vw",
                                      height: "calc(70vw / (16/9))",
                                    }
                                  : { width: "80vw", height: "calc(45vw)" }
                                : {}
                            }
                            src={this.state.MediaModalInfo.link}
                          ></iframe>
                        )}
                        {this.state.MediaModalInfo.type ===
                          HotspotType.Notice && (
                          <>
                            <iframe
                              title={"iframe"}
                              allow="camera *;microphone *"
                              className="media-modal-content-iframe"
                              src={this.state.MediaModalInfo.link}
                            ></iframe>
                            <img
                              style={{ width: "3rem !important" }}
                              src="/assets/images/homeImg.png"
                              alt=""
                              onClick={this.closeMediaModal}
                              className="closeNotice"
                            />
                          </>
                        )}
                        {this.state.MediaModalInfo.type ===
                          HotspotType.qrCode && (
                          <div
                            style={{
                              height: "70vh",
                              width: "70vh",
                              backgroundImage: `url(${this.state.MediaModalInfo.link})`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          ></div>
                        )}
                        {this.state.MediaModalInfo.type ===
                          HotspotType.video && (
                          <div
                            className="media-modal-content"
                            style={{
                              height: "70vh",
                              width: "calc(70vh * 1.77)",
                              position: "relative",
                            }}
                          >
                            {this.state.showVideoLoaders && (
                              <img
                                src={"assets/images/loader.svg"}
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%,-50%)",
                                  width: "4rem",
                                }}
                                alt=""
                              />
                            )}
                            <ReactPlayer
                              config={{
                                youtube: {
                                  playerVars: { showinfo: 1 },
                                },
                              }}
                              onReady={() => {
                                this.setState({ showVideoLoaders: false });
                              }}
                              playing={true}
                              url={this.state.MediaModalInfo.link}
                              volume={0.5}
                              controls={false}
                              width="100%"
                              height="100%"
                            />
                          </div>
                        )}
                        {this.state.MediaModalInfo.type ===
                          HotspotType.image && (
                          <img
                            style={{ width: "3rem !important" }}
                            title={"image"}
                            className="media-modal-content-image"
                            src={this.state.MediaModalInfo.link}
                            alt="mediaModalImage"
                          ></img>
                        )}
                        {this.state.showCloseMediaModal &&
                          this.state.MediaModalInfo.type !==
                            HotspotType.videoPlayer &&
                          this.state.MediaModalInfo.type !==
                            HotspotType.Notice &&
                          this.state.MediaModalInfo.type !==
                            HotspotType.pdfPlayer && (
                            <div
                              className={`closeButton-mediaModal ${
                                this.state.MediaModalInfo.type ===
                                HotspotType.qrCode
                                  ? "qr-close"
                                  : ""
                              }`}
                              style={{
                                height: isMobileOnly ? "3rem" : "3rem",
                                top: "5px",
                                right: !this.isMediaHotspot() ? "95%" : "8%",
                              }}
                            >
                              <img
                                height="100%"
                                width="100%"
                                style={{
                                  width: !this.isMediaHotspot()
                                    ? "3rem"
                                    : "3rem",
                                }}
                                src={
                                  this.state.MediaModalInfo.type ===
                                    HotspotType.video ||
                                  this.state.MediaModalInfo.type ===
                                    HotspotType.pdf ||
                                  this.state.MediaModalInfo.type ===
                                    HotspotType.qrCode
                                    ? ImageString.CLOSEBUTTON2
                                    : ImageString.CLOSEBUTTON
                                }
                                // className="closeButton-mediaModal"
                                alt="MediaModalCLoseButton"
                                onClick={this.closeMediaModal}
                              ></img>
                            </div>
                          )}
                      </div>
                    </article>
                  </>
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Audi && (
                  <Lottie
                    style={{
                      position: "fixed",
                      zIndex: 111,
                      left: 0,
                      right: 0,
                      height: "100vh",
                      width: "100%",
                      pointerEvents: "none",
                      visibility: this.state.playlikeLottie
                        ? "visible"
                        : "hidden",
                    }}
                    path={"/like-anim/Likes.json"}
                    play={this.state.playlikeLottie}
                    loop={true}
                    onLoopComplete={() => {
                      this.setState({ playlikeLottie: false });
                    }}
                    onLoad={() => {
                      console.log("ready to play");
                    }}
                  />
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Audi && (
                  <Lottie
                    style={{
                      position: "fixed",
                      zIndex: 111,
                      left: 0,
                      right: 0,
                      height: "100vh",
                      width: "100%",
                      pointerEvents: "none",
                      visibility: this.state.playheartLottie
                        ? "visible"
                        : "hidden",
                    }}
                    path={"/heart-anim/Heart.json"}
                    play={this.state.playheartLottie}
                    loop={true}
                    onLoopComplete={() => {
                      this.setState({ playheartLottie: false });
                    }}
                    onLoad={() => {
                      console.log("ready to play");
                    }}
                  />
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Audi && (
                  <Lottie
                    style={{
                      position: "fixed",
                      zIndex: 111,
                      left: 0,
                      right: 0,
                      height: "100vh",
                      width: "100%",
                      pointerEvents: "none",
                      visibility: this.state.playClapLottie
                        ? "visible"
                        : "hidden",
                    }}
                    path={"/clap-anim/Clap.json"}
                    play={this.state.playClapLottie}
                    loop={true}
                    onLoopComplete={() => {
                      this.setState({ playClapLottie: false });
                    }}
                    onLoad={() => {
                      console.log("ready to play");
                    }}
                  />
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Lobby && (
                  <>
                    <Scene
                      currentZoneName={this.state.currentHotspot}
                      restrictedZones={this.state.restrictedZones}
                      hideLanguageToggle={this.hideLanguageToggle}
                      setResPopup={this.setResPopup}
                      stateChange={this.stateChange}
                      ShowMediaModal={this.showMediaModal}
                      initialVideo={VideoString.LOBBYLOOP}
                      firstVideoFrame={ImageString.LOBBYLOOP}
                      isImageScene={isIOS || window.parent.isMac15}
                      initalImage={ImageString.LOBBYLOOP}
                      initialHotspot={LobbyHotspots}
                      globalBackButton={false}
                      currLocation={this.state.currenLocation}
                      changeComponenet={this.handleClick}
                      ratio={videoRatios}
                      // for tut to work also pass name
                      checkIfAudioCanPlayUnderMediaModal={
                        this.checkIfAudioCanPlayUnderMediaModal
                      }
                      sceneName="Lobby"
                      showTut={true}
                      tutComponent={LobbyTut}
                      showingTutorialEvent={this.handleSceneTutorial} //for handling transition pass start and end
                      changeInternalStateHandler={this.handleLobbyInternalState}
                      //Analytics
                      addHotspotAnalytics={this.handleHotspotAnalytics}
                      addAnalytics={(value) => {
                        this.addComponentAnalytics(
                          AnalyticsLocations.Lobby,
                          value
                        );
                      }}
                    ></Scene>
                    {this.state.UI.showHelpdeskChat && (
                      <>
                        {this.context.email &&
                          this.state.HelpdeskChat.showChat && (
                            <>
                              {/* <NotLive continue={this.handleHelpdeskChatClose}></NotLive> */}
                              <div className="chat__container">
                                <div
                                  onClick={this.handleHelpdeskChatClose}
                                  className="cross"
                                >
                                  <div
                                    className="line"
                                    style={{ transform: "rotate(45deg)" }}
                                  ></div>
                                </div>
                                <iframe
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                  }}
                                  src={`/stallChat/index.html?${this.state.chatID}`}
                                  frameBorder="0"
                                ></iframe>
                                <div className="loding__container">
                                  <img src={Spinner} width="50px" />
                                </div>
                              </div>
                            </>
                          )}
                        {this.state.HelpdeskChat.showButton && <></>}
                      </>
                    )}
                  </>
                )}

                {this.state.UI.activeMenu.id === WallOfFameItems[0].id && (
                  <>
                    <Scene
                      currentZoneName={this.state.currentHotspot}
                      restrictedZones={this.state.restrictedZones}
                      hideLanguageToggle={this.hideLanguageToggle}
                      setResPopup={this.setResPopup}
                      stateChange={this.stateChange}
                      ShowMediaModal={this.showMediaModal}
                      initialVideo={VideoString.LOBBYBACKSIDE}
                      firstVideoFrame={ImageString.BACKLOOP}
                      isImageScene={isIOS || window.parent.isMac15}
                      initalImage={ImageString.BACKLOOP}
                      initialHotspot={LobbyBackHotspots}
                      currLocation={this.state.currenLocation}
                      globalBackButton={false}
                      changeComponenet={this.handleClick}
                      ratio={videoRatios}
                      // for tut to work also pass name
                      checkIfAudioCanPlayUnderMediaModal={
                        this.checkIfAudioCanPlayUnderMediaModal
                      }
                      sceneName="Lobby"
                      showTut={true}
                      tutComponent={LobbyTut}
                      showingTutorialEvent={this.handleSceneTutorial} //for handling transition pass start and end
                      changeInternalStateHandler={this.handleLobbyInternalState}
                      //Analytics
                      addHotspotAnalytics={this.handleHotspotAnalytics}
                      addAnalytics={(value) => {
                        this.addComponentAnalytics(
                          AnalyticsLocations.LobbyBack,
                          value
                        );
                      }}
                    ></Scene>
                    {this.state.UI.showHelpdeskChat && (
                      <>
                        {this.context.email &&
                          this.state.HelpdeskChat.showChat && (
                            <>
                              {/* <NotLive continue={this.handleHelpdeskChatClose}></NotLive> */}
                              <div className="chat__container">
                                <div
                                  onClick={this.handleHelpdeskChatClose}
                                  className="cross"
                                >
                                  <div
                                    className="line"
                                    style={{ transform: "rotate(45deg)" }}
                                  ></div>
                                </div>
                                <iframe
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                  }}
                                  src={`/stallChat/index.html?${this.state.chatID}`}
                                  frameBorder="0"
                                ></iframe>
                                <div className="loding__container">
                                  <img src={Spinner} width="50px" />
                                </div>
                              </div>
                            </>
                          )}
                        {this.state.HelpdeskChat.showButton && <></>}
                      </>
                    )}
                  </>
                )}

                {this.state.UI.activeMenu.id === menuItemsId.ProductBooth && (
                  <Scene
                    isImageScene={true}
                    initalImage={ImageString.Infodesk}
                    ShowMediaModal={this.showMediaModal}
                    initialHotspot={InfoHotspots}
                    globalBackButton={true}
                    changeComponenet={this.handleClick}
                    ratio={videoRatios}
                    // for tut to work also pass name
                    sceneName="Networking"
                    showTut={false}
                    tutComponent={NetworkingTut}
                    showingTutorialEvent={this.handleSceneTutorial} //for handling transition pass start and end
                    addHotspotAnalytics={this.handleHotspotAnalytics}
                    addAnalytics={(value) => {
                      this.setState({
                        currentHotspot: AnalyticsLocations.ProductBooth,
                      });
                      this.addComponentAnalytics(
                        AnalyticsLocations.ProductBooth,
                        value
                      );
                    }}
                  ></Scene>
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Audi && (
                  <>
                    {/* <FullScreenAudi */}
                    <AudiScene
                      withEnv={false}
                      changeComponenet={this.handleClick}
                      ShowMediaModal={this.showMediaModal}
                      initialVideo={AudiData.introVideo}
                      isImageScene={isIOS || window.parent.isMac15}
                      initalImage={ImageString.Audi}
                      globalBackButton={false}
                      link={this.state.audiLnk}
                      framePlacementStyle={AudiData.placementStyle}
                      hiddeMute={false}
                      toggleRotateScreen={this.props.toggleRotateScreen}
                      showScreenCover={true}
                      showControl={false}
                      //For Tutorial
                      sceneName="audi"
                      showTut={true}
                      tutComponent={AudiTut}
                      setLottiPlay={async (val, location, col) => {
                        this.setState({ [`${val}`]: true });
                        this.updatesLikeClapsHearts(col);
                        await addRealtimeHotspotAnalytics(
                          this.context,
                          location
                        );
                        if (location === "clap") {
                          if (this.clapref.current) {
                            this.clapref.current.audioEl.current.play();
                            setTimeout(() => {
                              if (this.clapref.current) {
                                this.clapref.current.audioEl.current.pause();
                                this.clapref.current.audioEl.current.load();
                              }
                            }, 5000);
                          }
                        }
                      }}
                      subMenus={this.state.UI.activeSubMenu}
                      //Analytics
                      addAnalytics={(value) => {
                        this.addComponentAnalytics(
                          AnalyticsLocations.Audi,
                          value
                        );
                      }}
                    ></AudiScene>
                    {/* </FullScreenAudi> */}
                    <div
                      className={`chatButtonContainer ${
                        this.state.UI.hideCommentsBtn
                          ? "commentSection-out"
                          : ""
                      }`}
                    >
                      <div
                        className="chatButton"
                        onClick={async (e) => {
                          if (e) {
                            e.preventDefault();
                          }
                          this.setState({ playlikeLottie: true });
                          this.updatesLikeClapsHearts("totalLikes");

                          await addRealtimeHotspotAnalytics(
                            this.context,
                            "likes"
                          );
                        }}
                        style={{
                          // background:
                          //   "linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(150, 150, 150, 0.4) 100%)",
                          backdropFilter: "blur(34px)",
                          // borderBottom: "0.5px solid #000000",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <AiFillLike
                            className="likeButton"
                            style={{ color: "#fff" }}
                          />
                          {/* <p style={{ color: "#fff", marginLeft: "0.3rem" }}>
                            {this.state.likeCount}
                          </p> */}
                        </div>
                      </div>
                      <div
                        className="chatButton"
                        onClick={async (e) => {
                          if (e) {
                            e.preventDefault();
                          }
                          console.log("in");
                          this.setState({ playheartLottie: true }, () => {
                            console.log(this.state.playheartLottie);
                          });
                          this.updatesLikeClapsHearts("totalHearts");
                          await addRealtimeHotspotAnalytics(
                            this.context,
                            "hearts"
                          );
                        }}
                        style={{
                          // background:
                          //   "linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(150, 150, 150, 0.4) 100%)",
                          backdropFilter: "blur(34px)",
                          // borderBottom: "0.5px solid #000000",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <AiFillHeart
                            className="heartButton"
                            style={{ color: "#fff" }}
                          />
                          {/* <p style={{ color: "#fff", marginLeft: "0.3rem" }}>
                            {this.state.heartCount}
                          </p> */}
                        </div>
                      </div>
                      <div
                        className="chatButton"
                        onClick={async (e) => {
                          if (e) {
                            e.preventDefault();
                          }
                          this.setState({ playClapLottie: true });
                          this.updatesLikeClapsHearts("totalClaps");
                          if (this.clapref.current) {
                            this.clapref.current.audioEl.current.play();
                            setTimeout(() => {
                              if (this.clapref.current) {
                                this.clapref.current.audioEl.current.pause();
                                this.clapref.current.audioEl.current.load();
                              }
                            }, 5000);
                          }
                          await addRealtimeHotspotAnalytics(
                            this.context,
                            "clap"
                          );
                        }}
                        style={{
                          // background:
                          //   "linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(150, 150, 150, 0.4) 100%)",
                          backdropFilter: "blur(34px)",
                          // borderBottom: "0.5px solid #000000",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="clap">
                            <FaHandPaper className="lower__hand clapButton" />
                          </div>
                          {/* <p style={{ color: "#fff", marginLeft: "0.3rem" }}>
                            {this.state.clapCount}
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {this.state.UI.activeMenu.id === "Workshop" && (
                  <>
                    <AudiScene
                      changeComponenet={this.handleClick}
                      ShowMediaModal={this.showMediaModal}
                      initialVideo={
                        window.isWkAdmin
                          ? this.state.BreakoutRoomHotspot[this.state.wk].frame
                          : this.state.BreakoutRoomHotspot[`${window.workshop}`]
                              .frame
                      }
                      globalBackButton={false}
                      framePlacementStyle={AudiData.workshopPlacement}
                      link={
                        window.isWkAdmin
                          ? this.state.BreakoutRoomHotspot[this.state.wk].link
                          : this.state.BreakoutRoomHotspot[`${window.workshop}`]
                              .link
                      }
                      hiddeMute={false}
                      isImageScene={isIOS || window.parent.isMac15}
                      initalImage={
                        window.isWkAdmin
                          ? this.state.BreakoutRoomHotspot[this.state.wk]
                              .initalImage
                          : this.state.BreakoutRoomHotspot[`${window.workshop}`]
                              .initalImage
                      }
                      toggleRotateScreen={this.props.toggleRotateScreen}
                      showScreenCover={true}
                      showControl={false}
                      //For Tutorial
                      sceneName="audi"
                      showTut={true}
                      tutComponent={AudiTut}
                      subMenus={this.state.UI.activeSubMenu}
                      //Analytics
                      addAnalytics={(value) => {
                        this.addComponentAnalytics(this.state.wk, value);
                      }}
                    ></AudiScene>
                    {/* <div
                      className={`chatButtonContainer ${
                        this.state.UI.hideCommentsBtn
                          ? "commentSection-out"
                          : ""
                      }`}
                      style={{ top: "85vh" }}
                    >
                      <div
                        className={`chatButton ${
                          this.state.UI.showPoll && "bg-red"
                        }`}
                        onClick={(e) => {
                          if (e) {
                            e.preventDefault();
                          }
                          this.setState((prev) => ({
                            UI: {
                              ...prev.UI,
                              showPoll: !prev.UI.showPoll,
                              hideCommentsBtn: true,
                            },
                          }));
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={ImageString.PollButton}
                            alt="chatButton"
                            width="20px"
                          ></img>
                          <span
                            className="chat-icon-text1"
                            style={{ marginLeft: "1rem", marginTop: 0 }}
                          >
                            Poll
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </>
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Explore && (
                  <>
                    <Scene
                      forAdmin={this.adminAccess}
                      ShowMediaModal={this.showMediaModal}
                      initialVideo={VideoString.BREAKOUTLOOP}
                      firstVideoFrame={ImageString.Breakout}
                      isImageScene={isIOS || window.parent.isMac15}
                      initalImage={ImageString.Breakout}
                      initialHotspot={DiviSionBoothHotspost}
                      globalBackButton={true}
                      changeComponenet={this.handleClick}
                      ratio={videoRatios}
                      dbUpdated={this.props.dbUpdated}
                      forWorkshopRooms={true}
                      showAMPM={true}
                      // for tut to work also pass name
                      sceneName="Networking"
                      showTut={false}
                      tutComponent={NetworkingTut}
                      showingTutorialEvent={this.handleSceneTutorial} //for handling transition pass start and end
                      //  changeInternalStateHandler={this.handleLobbyInternalState}
                      //Analytics
                      addHotspotAnalytics={this.handleHotspotAnalytics}
                      addAnalytics={(value) => {
                        this.addComponentAnalytics(
                          AnalyticsLocations.Networking,
                          value
                        );
                      }}
                    ></Scene>
                    {/* <AudiScene
                      changeComponenet={this.handleClick}
                      ShowMediaModal={this.showMediaModal}
                      initialVideo={VideoString.WORKSHOP}
                      globalBackButton={false}
                      framePlacementStyle={AudiData.workshopPlacement}
                      link={this.state.qna_zone_link}
                      hiddeMute={false}
                      toggleRotateScreen={this.props.toggleRotateScreen}
                      showScreenCover={true}
                      showControl={false}
                      //For Tutorial
                      sceneName="audi"
                      showTut={true}
                      tutComponent={AudiTut}
                      subMenus={this.state.UI.activeSubMenu}
                      // Analytics
                      addAnalytics={(value) => {
                        this.addComponentAnalytics(
                          AnalyticsLocations.QNA,
                          value
                        );
                      }}
                    ></AudiScene> */}
                    {/* <div
                      className={`chatButtonContainer ${
                        this.state.UI.hideCommentsBtn
                          ? "commentSection-out"
                          : ""
                      }`}
                    >
                      <div
                        className={`chatButton ${
                          this.state.UI.showComments && "bg-red"
                        }`}
                        onClick={(e) => {
                          if (e) {
                            e.preventDefault();
                          }
                          this.setState((prev) => ({
                            UI: {
                              ...prev.UI,
                              showComments: !prev.UI.showComments,
                              showPoll: false,
                              hideCommentsBtn: true,
                            },
                          }));
                        }}
                        style={{ borderBottom: ".8px solid #fff" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={ImageString.ChatButton}
                            alt="chatButton"
                            width="20px"
                          ></img>
                          <span className="chat-icon-text1 ">Q&amp;A</span>
                        </div>
                      </div>
                      <div
                        className={`chatButton ${
                          this.state.UI.showPoll && "bg-red"
                        }`}
                        onClick={(e) => {
                          if (e) {
                            e.preventDefault();
                          }
                          this.setState((prev) => ({
                            UI: {
                              ...prev.UI,
                              showPoll: !prev.UI.showPoll,
                              showComments: false,
                              hideCommentsBtn: true,
                            },
                          }));
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={ImageString.PollButton}
                            alt="chatButton"
                            width="20px"
                          ></img>
                          <span className="chat-icon-text1">Polls</span>
                        </div>
                      </div>
                    </div> */}
                  </>
                )}
                {this.state.UI.activeMenu.id ===
                  GalleryItems[GalleryIndex.Consumer].id && (
                  <>
                    <ImageGallery
                      data={imagesData.Consumer}
                      backBtn={(e) => {
                        this.handleClick(e, menuItems[menuItemsIndex.Explore]);
                      }}
                      addAnalytics={(value) => {
                        this.addComponentAnalytics(
                          imagesData.Consumer.title,
                          value
                        );
                      }}
                    />
                    <img
                      src="/assets/images/homeImg.png"
                      alt=""
                      onClick={(e) => {
                        this.handleClick(e, menuItems[menuItemsIndex.Explore]);
                      }}
                      className="closeNotice"
                    />
                  </>
                )}
                {this.state.UI.activeMenu.id ===
                  GalleryItems[GalleryIndex.Luxe].id && (
                  <>
                    <ImageGallery
                      data={imagesData.Luxe}
                      backBtn={(e) => {
                        this.handleClick(e, menuItems[menuItemsIndex.Explore]);
                      }}
                      addAnalytics={(value) => {
                        this.addComponentAnalytics(
                          imagesData.Luxe.title,
                          value
                        );
                      }}
                    />
                    <img
                      src="/assets/images/homeImg.png"
                      alt=""
                      onClick={(e) => {
                        this.handleClick(e, menuItems[menuItemsIndex.Explore]);
                      }}
                      className="closeNotice"
                    />
                  </>
                )}
                {this.state.UI.activeMenu.id === menuItemsId.DivisionBooth && (
                  <Scene
                    isImageScene={true}
                    initalImage={ImageString.DIVISION}
                    ShowMediaModal={this.showMediaModal}
                    initialHotspot={DiviSionBoothHotspost}
                    globalBackButton={true}
                    changeComponenet={this.handleClick}
                    ratio={videoRatios}
                    for
                    tut
                    to
                    work
                    also
                    pass
                    name
                    sceneName="Networking"
                    showTut={false}
                    tutComponent={NetworkingTut}
                    showingTutorialEvent={this.handleSceneTutorial}
                    addHotspotAnalytics={this.handleHotspotAnalytics}
                    addAnalytics={(value) => {
                      this.setState({
                        currentHotspot: AnalyticsLocations.DivisionBooth,
                      });
                      this.addComponentAnalytics(
                        AnalyticsLocations.DivisionBooth,
                        value
                      );
                    }}
                  ></Scene>
                )}
                {this.state.UI.activeMenu.id ===
                  GalleryItems[GalleryIndex.Professional].id && (
                  <>
                    <ImageGallery
                      data={imagesData.Professional}
                      backBtn={(e) => {
                        this.handleClick(e, menuItems[menuItemsIndex.Explore]);
                      }}
                      addAnalytics={(value) => {
                        this.addComponentAnalytics(
                          imagesData.Professional.title,
                          value
                        );
                      }}
                    />
                    {/* <img
                      src="/assets/images/homeImg.png"
                      alt=""
                      onClick={(e) => {
                        this.handleClick(e, menuItems[menuItemsIndex.Explore]);
                      }}
                      className="closeNotice"
                    /> */}
                  </>
                )}
                {this.state.UI.activeMenu.id ===
                  GalleryItems[GalleryIndex.Active].id && (
                  <>
                    <ImageGallery
                      data={imagesData.Active}
                      backBtn={(e) => {
                        this.handleClick(e, menuItems[menuItemsIndex.Explore]);
                      }}
                      addAnalytics={(value) => {
                        this.addComponentAnalytics(
                          imagesData.Active.title,
                          value
                        );
                      }}
                    />
                    {/* <img
                      src="/assets/images/homeImg.png"
                      alt=""
                      onClick={(e) => {
                        this.handleClick(e, menuItems[menuItemsIndex.Explore]);
                      }}
                      className="closeNotice"
                    /> */}
                  </>
                )}
                {this.state.UI.activeMenu.id === menuItemsId.BreakoutRoom && (
                  <>
                    <Scene
                      forAdmin={this.adminAccess}
                      ShowMediaModal={this.showMediaModal}
                      initialVideo={VideoString.WORKSHOPLOBBY}
                      firstVideoFrame={ImageString.WorkShop}
                      isImageScene={isIOS || window.parent.isMac15}
                      initalImage={ImageString.WorkShop}
                      initialHotspot={this.state.BreakoutRoomHotspot}
                      globalBackButton={true}
                      changeComponenet={this.handleClick}
                      ratio={videoRatios}
                      dbUpdated={this.props.dbUpdated}
                      forWorkshopRooms={true}
                      showAMPM={true}
                      // for tut to work also pass name
                      sceneName="Networking"
                      showTut={false}
                      tutComponent={NetworkingTut}
                      showingTutorialEvent={this.handleSceneTutorial} //for handling transition pass start and end
                      //  changeInternalStateHandler={this.handleLobbyInternalState}
                      //Analytics
                      addHotspotAnalytics={this.handleHotspotAnalytics}
                      addAnalytics={(value) => {
                        this.addComponentAnalytics(
                          AnalyticsLocations.Workshop,
                          value
                        );
                      }}
                    ></Scene>
                    {/* <BreakoutMenu
                      user={this.context}
                      hotspots={this.state.BreakoutRoomHotspot}
                      handleClick={this.handleClick}
                      setCurrentWorkshopForAdmin={
                        this.setCurrentWorkshopForAdmin
                      }
                    /> */}
                  </>
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Meet && (
                  <>
                    <Scene
                      isImageScene={true}
                      initalImage={ImageString.ProductStall}
                      ShowMediaModal={this.showMediaModal}
                      // initialVideo={VideoString.BREAKOUTLOOP}
                      // firstVideoFrame={ImageString.Breakout}
                      initialHotspot={MeetTheHeroHotspot}
                      globalBackButton={false}
                      changeComponenet={this.handleClick}
                      ratio={videoRatios}
                      // for tut to work also pass name
                      sceneName="Networking"
                      showTut={false}
                      tutComponent={NetworkingTut}
                      showingTutorialEvent={this.handleSceneTutorial} //for handling transition pass start and end
                      //  changeInternalStateHandler={this.handleLobbyInternalState}
                      //Analytics
                      addHotspotAnalytics={this.handleHotspotAnalytics}
                      addAnalytics={(value) =>
                        this.addComponentAnalytics(
                          AnalyticsLocations.MeetTheHero,
                          value
                        )
                      }
                    ></Scene>
                  </>
                )}
                {this.state.UI.overlayMenu && (
                  <>
                    {this.state.UI.overlayMenu.id ===
                      menuItemsId.TeamBuilding && (
                      <>
                        <Connect
                          close={this.hideOverlayMenu}
                          addAnalytics={(value) =>
                            this.addComponentAnalytics(
                              AnalyticsLocations.Teambuilding,
                              value
                            )
                          }
                        ></Connect>
                      </>
                    )}
                  </>
                )}
                {this.state.UI.activeMenu.id === menuItemsId.Explore &&
                  this.state.UI.activeSubMenu && (
                    <>
                      {this.state.UI.activeSubMenu.id ===
                        NetworkingSubmenuId.Speaker && (
                        <article
                          className={`img-bg videoBox h-100 zIndex50`}
                          id="play"
                        >
                          <div className="media-modal">
                            <iframe
                              title={"pdf"}
                              className="media-modal-content"
                              src={NetworkingLounge.speakerPdf}
                            ></iframe>
                            <img
                              style={{ width: "3rem" }}
                              src={ImageString.CLOSEBUTTON}
                              className="closeButton-mediaModal"
                              alt="MediaModalCLoseButton"
                              onClick={this.closeSubMenu}
                            ></img>
                          </div>
                        </article>
                      )}
                      {this.state.UI.activeSubMenu.id ===
                        NetworkingSubmenuId.Sessions && (
                        <>
                          <VideoPlayer
                            videoData={VideoPlayerData}
                            close={() => this.closeSubMenu()}
                          ></VideoPlayer>
                        </>
                      )}
                      {this.state.UI.activeSubMenu.id ===
                        NetworkingSubmenuId.Connect && (
                        <Connect close={this.closeSubMenu}></Connect>
                      )}
                      {this.state.UI.activeSubMenu.id ===
                        NetworkingSubmenuId.Chat && (
                        <div className="scrollableIframeParent">
                          <iframe
                            className="publicChatBox"
                            src="/Chat/index.html"
                            title="chatFrame"
                          ></iframe>
                        </div>
                      )}
                    </>
                  )}

                {
                  <>
                    {true && (
                      <footer
                        className={`footerBox  ${isIOS ? "footer-ios" : ""}`}
                      >
                        {this.state.UI.overlayMenu && (
                          <>
                            {this.state.UI.overlayMenu.id ===
                              menuItemsId.MyProfile && (
                              <div>
                                <MyProfile
                                  allData={this.state.allData}
                                  data={this.state.scoreCard__Data}
                                  email={this.context.email}
                                  close={this.hideOverlayMenu}
                                  ShowMediaModal={
                                    this.handleMedialModalFromOverlay
                                  }
                                ></MyProfile>
                              </div>
                            )}
                          </>
                        )}
                        {this.state.UI.activeMenu &&
                          !this.state.UI.activeMenu.subMenus && (
                            <Menu
                              items={this.state.UI.menuItems}
                              activeItemId={
                                this.state.UI.overlayMenu
                                  ? this.state.UI.overlayMenu.id
                                  : this.state.UI.activeMenu.id === "Workshop"
                                  ? menuItemsId.BreakoutRoom
                                  : this.state.UI.activeMenu.id
                              }
                              onMenuItemClick={this.handleClick}
                              canInteract={this.state.UI.isInteractable}
                            ></Menu>
                          )}
                        {this.state.UI.activeMenu.subMenus && (
                          <Menu
                            items={this.state.UI.activeMenu.subMenus}
                            activeItemId={
                              this.state.UI.activeSubMenu
                                ? this.state.UI.activeSubMenu.id
                                : -1
                            }
                            onMenuItemClick={this.handleSubmenuItemClick}
                            canInteract={this.state.UI.isInteractable}
                            backButton={true}
                            onBackButtonClick={(e) =>
                              this.handleClick(
                                e,
                                window.isWkAdmin
                                  ? this.state.UI.activeMenu.id === "Workshop"
                                    ? menuItems[menuItemsIndex.BreakoutRoom]
                                    : menuItems[menuItemsIndex.Lobby]
                                  : menuItems[menuItemsIndex.Lobby]
                              )
                            }
                          ></Menu>
                        )}
                        {this.state.UI.activeSubMenu &&
                          this.state.UI.activeMenu.id === "Workshop" && (
                            <>
                              <div
                                className={`submenu-container active expended ${
                                  isIOS ? "ipad-submenu-container" : ""
                                }`}
                              >
                                {this.state.UI.activeSubMenu.id ===
                                  AudisubMenuId.QNA && (
                                  <QNA
                                    headerName={"Q&A"}
                                    onHeadingClick={this.closeSubMenu}
                                    QNACollection={
                                      window.isWkAdmin
                                        ? `qna-${this.state.wk}`
                                        : `qna-${window.workshop}`
                                    }
                                    QNAReplyCollection={`qna-reply-${window.workshop}`}
                                    canReply={false}
                                    moderated={true}
                                  ></QNA>
                                )}
                                {this.state.UI.activeSubMenu.id ===
                                  AudisubMenuId.POll && (
                                  <PublicPoll
                                    PollAdmin_Col={
                                      window.isWkAdmin
                                        ? `poll-admin-${this.state.wk}`
                                        : `poll-admin-${window.workshop}`
                                    }
                                    PollAdmin_Doc={AppString.PollAdmin_Doc}
                                    Poll_Doc={
                                      window.isWkAdmin
                                        ? `poll-${this.state.wk}`
                                        : `poll-${window.workshop}`
                                    }
                                    PollResponse_Col={
                                      window.isWkAdmin
                                        ? `poll-response${this.state.wk}`
                                        : `poll-response${window.workshop}`
                                    }
                                    forceOpen={() =>
                                      this.handlePollForceOpen(
                                        menuItemsIndex.Audi,
                                        AudisubMenuId.POll
                                      )
                                    }
                                    // pollAdminCollection={

                                    // }
                                    // pollAdminDoc={}
                                    // pollCollection={

                                    // }
                                    onHeadingClick={this.closeSubMenu}
                                  ></PublicPoll>
                                )}
                              </div>
                            </>
                          )}
                        {this.state.UI.activeSubMenu &&
                          this.state.UI.activeMenu.id === menuItemsId.Audi && (
                            <>
                              <div
                                className={`submenu-container active expended`}
                              >
                                {this.state.UI.activeSubMenu.id ===
                                  AudisubMenuId.QNA && (
                                  <QNA
                                    headerName={"Q&A"}
                                    onHeadingClick={this.closeSubMenu}
                                    QNACollection={`qna-bd`}
                                    QNAReplyCollection={`qna-reply-bd`}
                                    canReply={false}
                                    moderated={true}
                                  ></QNA>
                                )}
                                {this.state.UI.activeSubMenu.id ===
                                  AudisubMenuId.POll && (
                                  <PublicPoll
                                    PollAdmin_Col={AppString.PollAdmin_Col}
                                    PollAdmin_Doc={AppString.PollAdmin_Doc}
                                    Poll_Doc={AppString.Poll_Doc}
                                    PollResponse_Col={"pollResponse"}
                                    forceOpen={() =>
                                      this.handlePollForceOpen(
                                        menuItemsIndex.Audi,
                                        AudisubMenuId.POll
                                      )
                                    }
                                    onHeadingClick={this.closeSubMenu}
                                  ></PublicPoll>
                                )}
                              </div>
                            </>
                          )}
                      </footer>
                    )}
                    {/* {this.state.UI.activeMenu.id === menuItemsId.Explore &&
                      this.state.UI.showComments && (
                        <>
                          <div
                            className={`commentSection flipShell ${
                              this.state.UI.hideCommentsBox
                                ? "commentSection-out"
                                : ""
                            }`}
                          >
                            <AudiQNA
                              headerName={"Q&A"}
                              onHeadingClick={this.closeCommentBox}
                              QNACollection={`qna-bd`}
                              QNAReplyCollection={`qnaBreakoutReply`}
                              canReply={false}
                              moderated={true}
                            ></AudiQNA>
                          </div>
                        </>
                      )} */}

                    {/* {this.state.UI.activeMenu.id === menuItemsId.Explore && (
                      <div
                        className={`pollSection flipShell ${
                          this.state.UI.showPoll &&
                          (this.state.UI.hideCommentsBox
                            ? "pollSection-close"
                            : "pollSection-open")
                        }`}
                      >
                        <AudiPoll
                          pollAdminCollection={"QNA-ZONE-POLL-ADMIN"}
                          pollAdminDoc={AppString.PollAdmin_Doc}
                          pollCollection={"QNA-ZONE-POLL"}
                          onHeadingClick={this.closePoll}
                          forceOpen={() => {
                            this.setState((prev) => ({
                              UI: {
                                ...prev.UI,
                                showPoll: true,
                                hideCommentsBtn: true,
                              },
                            }));
                          }}
                        ></AudiPoll>
                      </div>
                    )} */}
                    {/* {this.state.UI.activeMenu.id === menuItemsId.Audi && (
                      <div
                        className={`pollSection flipShell ${
                          this.state.UI.showPoll &&
                          (this.state.UI.hideCommentsBox
                            ? "pollSection-close"
                            : "pollSection-open")
                        }`}
                      >
                        <AudiPoll
                          pollAdminCollection={AppString.PollAdmin_Col}
                          pollAdminDoc={AppString.PollAdmin_Doc}
                          pollCollection={AppString.Poll_Doc}
                          onHeadingClick={this.closePoll}
                          forceOpen={() => {
                            this.setState((prev) => ({
                              UI: {
                                ...prev.UI,
                                showPoll: true,
                                hideCommentsBtn: true,
                              },
                            }));
                          }}
                        ></AudiPoll>
                      </div>
                    )} */}
                    {/* {this.state.UI.activeMenu.id === "Workshop" && (
                      <div
                        className={`pollSection flipShell ${
                          this.state.UI.showPoll &&
                          (this.state.UI.hideCommentsBox
                            ? "pollSection-close"
                            : "pollSection-open")
                        }`}
                      >
                        <AudiPoll
                          pollAdminCollection={
                            window.isWkAdmin
                              ? `poll-admin-${this.state.wk}`
                              : `poll-admin-${window.workshop}`
                          }
                          pollAdminDoc={AppString.PollAdmin_Doc}
                          pollCollection={
                            window.isWkAdmin
                              ? `poll-${this.state.wk}`
                              : `poll-${window.workshop}`
                          }
                          onHeadingClick={this.closePoll}
                          forceOpen={() => {
                            this.setState((prev) => ({
                              UI: {
                                ...prev.UI,
                                showPoll: true,
                                hideCommentsBtn: true,
                              },
                            }));
                          }}
                        ></AudiPoll>
                      </div>
                    )} */}
                  </>
                }
              </section>
            </div>
          </>
        )}

        {this.checkIfAudioCanPlayUnderMediaModal(
          false,
          this.state.audio ? true : false
        ) &&
          this.state.currentHotspot != "TheUltimateWinner" &&
          this.state.currentHotspot != "TheUltimateJuryChoice" &&
          this.state.currentHotspot != "TheUltimateRunnerUp" &&
          this.state.currentHotspot != "TheUltimatePopularChoice" &&
          (this.state.UI.activeMenu.id === menuItemsId.Lobby ||
            this.state.UI.activeMenu.id === WallOfFameItems[0].id) && (
            <>
              <ReactAudioPlayer
                src="/3dAssets/music/ambientMusic.mp3"
                autoPlay
                volume={0.1}
                loop={true}
                preload="auto"
                ref={this.currentAudioRef}
              />
            </>
          )}
        {!(
          isMobileOnly && this.state.UI.activeMenu.id === menuItemsId.Audi
        ) && (
          <div className="leftBottom">
            <img src="/assets/images/D2C.png"></img>
          </div>
        )}
        {this.state.UI.activeMenu.id === menuItemsId.Audi && (
          <ReactAudioPlayer
            src={clapSound}
            autoPlay={false}
            volume={0.5}
            loop={false}
            preload="auto"
            onEnded={() => {
              this.clapref.current.audioEl.current.load();
            }}
            ref={this.clapref}
          />
        )}
        {/* {this.state.canShowRotate && (
          <>
            <div className="rotate-screen-popup">
              <div id="rotate">
                <div className="phone"></div>
                <div className="message mm">Please rotate your device!</div>
              </div>

            </div>
          </>
        )} */}
      </>
    );
  }
}
Home.contextType = UserContext;

export default Home;
