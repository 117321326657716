export const HotspotType = {
  transition: "transition",
  iframe: "iframe",
  video: "video",
  image: "image",
  pdf: "pdf",
  anchor: "anchor",
  videoPlayer: "videoPlayer",
  imagePlayer: "imagePlayer",
  pdfPlayer: "pdfPlayer",
  inlineVideo: "inlineVideo",
  chatbot: "chatbot",
  modiFace: "modiFace",
  qrCode: "qrCode",
  Notice: "Notice",
};

export const TransitionType = {
  withInComponent: 0,
  changeComponent: 1,
  changeOverlayComponent: 2,
};

export const HotspotButtonType = {
  default: "default",
  named: "named",
  wallRotate: "wallRotate",
};

export const AnalyticsLocations = {
  Lobby: "lobby",
  LobbyBack: "LobbyBack",
  Audi: "audi",
  Networking: "Discover RELIANCE",
  Workshop: "Career cafe",
  Teambuilding: "idea Wall",
  MeetTheHero: "meetTheHero",
  Scorecard: "scorecard",
  QNA: "QNA",
  ProductBooth: "ProductBooth",
  DivisionBooth: "DivisionBooth",
};
export const workshopsName = {
  "workshop-1": "humanResources",
  "workshop-2": "generalManagement",
  "workshop-3": "finance",
  "workshop-4": "operations",
  "workshop-5": "Digital track",
  "workshop-6": "Finance track",
  "workshop-7": "HR track",
};
export const VideoType = {
  youtube: "youtube",
  vimeo: "vimeo",
};

export const PointType = {
  InfoDesk: "InfoDesk",
  Photobooth: "Photobooth",
  TeamBuilding: "TeamBuilding",
  Audi: "Audi",
  NetworkingConnect: "NetworkingConnect",
  NetworkingSession: "NetworkingSession",
  NetworkingSpeakerProfile: "NetworkingSpeakerProfile",
  NetworkingChat: "NetworkingChat",
  Game: "Game",
};
export const PointValues = {
  // Infodesk: { value: 50, oneTime: true, name: "Infodesk" },
  Photomosaic: { value: 100, oneTime: false, name: "Photomosaic " },
  // ResourceCenter: { value: 20, oneTime: false, name: "ResourceCenter" },
  // WorkshopPM: { value: 80, oneTime: true, name: "WorkshopPM" },
  //
  Workhop_SurveyAM: { value: 80, oneTime: true, name: "WorkshopAM" },
  Workhop_SurveyPM: { value: 80, oneTime: true, name: "WorkshopPM" },
  resourcecenter_FAQ: {
    value: 50,
    oneTime: false,
    name: "resourcecenter_FAQ",
  },
  resourcecenter_program: {
    value: 50,
    oneTime: false,
    name: "resourcecenter_program",
  },
  Keynote_Bio: {
    value: 50,
    oneTime: false,
    name: "Keynote_Bio",
  },
  People_Matter_Survey: {
    value: 50,
    oneTime: false,
    name: "People_Matter_Survey",
  },
  Wellbeing: { value: 50, oneTime: false, name: "Wellbeing" },
  Cuppa_of_Life: { value: 50, oneTime: false, name: "Cuppa_of_Life" },
  resourcecenter_survey: {
    value: 50,
    oneTime: false,
    name: "resourcecenter_survey",
  },
  resourcecenter_survey_workshop: {
    value: 50,
    oneTime: false,
    name: "resourcecenter_survey_workshop",
  },
  Survey: { value: 80, oneTime: true, name: "Survey" },
  // WorkshopAM: { value: 80, oneTime: true, name: "WorkshopAM" },
  Videos: { value: 50, oneTime: false, name: "Videos" },
  TeamBuilding: { value: 100, oneTime: true, name: "TeamBuilding" },
  Audi: { value: 20, oneTime: false, name: "Audi" },
  NetworkingConnect: { value: 20, oneTime: false, name: "NetworkingConnect" },
  NetworkingSession: { value: 20, oneTime: false, name: "NetworkingSession" },
  NetworkingSpeakerProfile: {
    value: 20,
    oneTime: false,
    nam: "NetworkingSpeakerProfile",
  },
  NetworkingChat: { value: 20, oneTime: false, name: "NetworkingChat" },
  Word_Hunt: { value: 20, oneTime: false, name: "Word_Hunt" },
};
