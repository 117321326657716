import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { UserProvider } from "../auth/providers";
import { addRealtimeHotspotAnalytics } from "../firebase/firebase";
import headerBg from "./headerBg.png";
import "./BreakoutMenu.css";
import close from "./close.svg";
import { menuItems, menuItemsIndex } from "../../const/Menu/MenuConst";
function BreakoutMenu(props) {
  useEffect(() => {
    // console.log(props);
  }, []);
  const checkForDisabled = (key) => {
    if (window.isWkAdmin) {
      return false;
    }
    if (window.workshop === key) {
      return false;
    }
    return true;
  };
  return (
    <div className="breakout__scene">
      <div className="tint__overlay">
        <div className="breakout__popup">
          <div className="breakout__header">
            {/* <img src={headerBg} alt="" className="bg_img" /> */}
            <h2 className="rooms_heading">Breakout Room</h2>
            <img
              src={close}
              alt=""
              className="close_img"
              onClick={(e) => {
                props.handleClick(e, menuItems[menuItemsIndex.Lobby]);
              }}
            />
          </div>
          <div className="breakout__options">
            {Object.keys(props.hotspots).map((key) => {
              if (!props.hotspots[key].enabled) {
                return;
              }
              return (
                <div key={key} className="single__room">
                  <div className="name__desc">
                    <h1>{props.hotspots[key].name}</h1>
                    <p>{props.hotspots[key].title}</p>
                  </div>
                  <div className="connect__btn">
                    <button
                      disabled={checkForDisabled(key)}
                      style={{
                        pointerEvents: checkForDisabled(key) ? "none" : "all",
                        opacity: checkForDisabled(key) ? "0.6" : "1",
                      }}
                      onClick={async (e) => {
                        if (window.isWkAdmin) {
                          props.setCurrentWorkshopForAdmin(key);
                          await addRealtimeHotspotAnalytics(props.user, key);
                        } else {
                          if (key !== window.workshop) {
                            return;
                          } else {
                            props.handleClick(e, props.hotspots[key].newItem);
                            await addRealtimeHotspotAnalytics(props.user, key);
                          }
                        }
                      }}
                    >
                      Connect
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BreakoutMenu;
