import React, { useEffect, useRef, useState } from "react";
export const TimerContext = React.createContext();
function TimerContextProvider(props) {
  const [messageSent, setMessageSent] = useState(0);
  const [timeLeft, setTimeLeft] = useState(15);
  const intervalRef = useRef();
  useEffect(() => {
    if (messageSent === 5) {
      intervalRef.current = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      setTimeout(() => {
        setMessageSent(0);
        setTimeLeft(15);
        clearInterval(intervalRef.current);
      }, 15000);
    }
  }, [messageSent]);
  return (
    <TimerContext.Provider
      value={{
        messageSent,
        setMessageSent,
        timeLeft,
      }}
    >
      {props.children}
    </TimerContext.Provider>
  );
}

export default TimerContextProvider;
