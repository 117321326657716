import React, { Component } from "react";
import {
  attachConnectProfilesListener,
  removeConnectProfilesListener,
} from "../firebase/firebase";
import "./Connect.css";
const HeaderBackground = {
  // background: 'url(/assets/images/background.png)',
  // backgroundPosition: 'center',
  // backgroundSize: 'contain'
  background: "#fff",
  boxShadow: "2px 2px 16px rgba(0,0,0,0.5)",
};
const LogoLeft = {
  background: "url(/assets/images/tutHeader.png)",
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
};
const LogoRight = {
  background: "url(/assets/images/header.png)",
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
};
const closeButton = {
  background: "url(/assets/images/close.png)",
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
};

export const connect_link = "https://in.yahoo.com/";

export const connectUserData = {
  "Vismay Sharma": {
    name: "Vismay Sharma",
    designation:
      "President of South Asia Pacific, Middle East and North Africa (SAPMENA), L'Oréal",
    linked_ln_profile: "https://www.linkedin.com/in/vismay-sharma-7132444/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c6e7dde35d_vismay_sharma.jpg",
  },
  "Shelly Chiang": {
    name: "Shelly Chiang",
    designation: "Chief Digital & Marketing Officer, SAPMENA, L'Oréal",
    linked_ln_profile: "https://www.linkedin.com/in/shelly-chiang-54a17/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c710d8a211_shelly_chiang.jpg",
  },
  "Jacob Thomas": {
    name: "Jacob Thomas",
    designation:
      "Vice President - Commercial, Consumer Products Division, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/jacob-thomas-3b661639/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c73f5d5d5f_jacob_photo_1.jpg",
  },
  "Suhas Lakhamade": {
    name: "Suhas Lakhamade",
    designation:
      "General Manager – Matrix and Cheryl's, Professional Products Division, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/suhas-lakhamade-59a0b58/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c6f8996eb7_suhas_lakhamade.jpg",
  },
  "Amit Ranga": {
    name: "Amit Ranga",
    designation: "Senior Manager - Human Resources, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/amitranga/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c7695737da_amit_ranga.jpg ",
  },
  "Pankaj Sharma": {
    name: "Pankaj Sharma",
    designation: "Director - Consumer Products Division, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/pankaj-sharma-4166561/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c72c7504c8_img_0199.jpg",
  },
  "Anjali Pai": {
    name: "Anjali Pai",
    designation:
      "Assistant General Manager - Marketing ,Professional Products Division, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/anjali-pai-7617358/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c7613497e4_anjali_pai.jpg",
  },
  "Trishta B Vardhan": {
    name: "Trishta B Vardhan",
    designation:
      "Manager - Marketing, Consumer Products Division, L'Oréal India",
    linked_ln_profile: "https://in.linkedin.com/in/trishta-b-vardhan-30852456",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c6f3aee134_trishta_b_vardhan.jpg",
  },

  "Niilesh Bhoite": {
    name: "Niilesh Bhoite",
    designation: "General Manager - Human Resources, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/niileshsbhoite/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c730c57b2a_niilesh_bhoite.jpg",
  },

  "Saloni Shah": {
    name: "Saloni Shah",
    designation:
      "Assistant General Manager - Digital, Consumer Products Division, L'Oréal India",
    linked_ln_profile:
      "https://www.linkedin.com/in/saloni-shah-javeri-aa4899b3/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c71727d1e3_saloni.jpg",
  },

  "Anil Chilla": {
    name: "Anil Chilla",
    designation: "Chief Digital & Data Officer, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/anilsrinivas/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c763d0e3ee_anil.jpg",
  },
  "Arushee Malhotra": {
    name: "Arushee Malhotra",
    designation:
      "Senior Manager - Digital, Consumer Products Division, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/arushee-malhotra-5817a796/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c75b8811ea_arushee_malhotra.jpg",
  },
  "Mansi Chanana": {
    name: "Mansi Chanana",
    designation: "Senior Manager - Human Resources, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/mansi-chanana-05179133/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c737b3ac90_mansi_chanana.jpg",
  },
  "Sai Prakash Mangipudi": {
    name: "Sai Prakash Mangipudi",
    designation: "Director - Operations, L'Oréal India",
    linked_ln_profile:
      "https://www.linkedin.com/in/sai-prakash-mangipudi-1bb9554/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/files/61306deb7e099_sai_prakash.jpeg",
  },
  "Anjesh Vinchurkar": {
    name: "Anjesh Vinchurkar",
    designation: "General Manager - Operations, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/anjesh-vinchurkar-3119a3b/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c75f2bb78a_anjesh_vinchunkar.jpg",
  },
  "Hirak Suryavanshi": {
    name: "Hirak Suryavanshi",
    designation: "Senior Manager - Operations, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/hiraksuryavanshi/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c7426c1e3d_hirak_suryavanshi.jpg",
  },
  "Rucha Sankhe": {
    name: "Rucha Sankhe",
    designation: "Senior Manager - Operations, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/rucha-sankhe-5ba3447/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c71c944bb2_rucha_sankhe.jpg",
  },
  "Rajesh Gopal": {
    name: "Rajesh Gopal",
    designation: "Chief Information Officer, L'Oréal India and MENA",
    linked_ln_profile: "https://www.linkedin.com/in/rajesh-gopal-9795505/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c7210095a9_2cp_8023.jpg",
  },
  "Abhishikta Tripathi": {
    name: "Abhishikta Tripathi",
    designation: "Senior Manager - Human Resources, L'Oréal India",
    linked_ln_profile:
      "https://www.linkedin.com/in/abhishikta-tripathi-32567829/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c7745df205_abhishikta_tripathi.jpg",
  },

  "Rajesh Gopal": {
    name: "Rajesh Gopal",
    designation: "Chief Information Officer, L'Oréal India and MENA",
    linked_ln_profile: "https://www.linkedin.com/in/rajesh-gopal-9795505/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c7210095a9_2cp_8023.jpg",
  },

  "Amna Husain": {
    name: "Amna Husain",
    designation: "Senior Manager - Human Resources, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/amnahusain/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c7666bdb50_amna_husain1_2.jpg",
  },

  "Gagandeep Singh": {
    name: "Gagandeep Singh",
    designation: "Senior Manager - IT, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/gds7/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c746745be9_gagandeep_singh.jpg",
  },

  "Jayram Madhusudan": {
    name: "Jayram Madhusudan",
    designation: "Assistant General Manager - IT, L'Oréal India",
    linked_ln_profile:
      "https://www.linkedin.com/in/jayram-madhusudan-29195612/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c73b017b50_jayram_madhusudan.jpg",
  },

  "Gaurav Mahajan": {
    name: "Gaurav Mahajan",
    designation: "Assistant General Manager - Human Resources, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/gaurav-mahajan-5248b511/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c7445172a1_img_0605.jpg",
  },

  "Aishwarya Shekhar": {
    name: "Aishwarya Shekhar",
    designation: "Assistant Manager - Human Resources, L'Oréal India",
    linked_ln_profile:
      "https://www.linkedin.com/in/aishwarya-shekhar-b349a1127/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c771709ae5_photo_aishwarya_shekhar.jpg",
  },

  "Roshni Wadhwa": {
    name: "Roshni Wadhwa",
    designation: "Director - Human Resources, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/roshni-wadhwa-66594812/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c71e84905b_mith7182.jpg",
  },

  "Nikita Arora": {
    name: "Nikita Arora",
    designation: "Management trainee - HR, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/nikita-arora-510/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c72ec5c33c_nikita_arora.jpg",
  },

  "R Srinivas": {
    name: "R Srinivas",
    designation: "General Manager - Finance and Accounts, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/srinivas-r-20a73a70/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c724ad2104_srini_photo.jpg",
  },

  "Ajit Singh": {
    name: "Ajit Singh",
    designation: "Manager - Financial Control, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/ajit-singh-23958136/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c76edc50a4_ajit_singh.jpg",
  },

  "Tanisha Govindarajan": {
    name: "Tanisha Govindarajan",
    designation: "General Manager - Financial Control, L'Oréal India",
    linked_ln_profile:
      "https://www.linkedin.com/in/tanisha-govindarajan-6551069/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c6f6a33b64_pic_2.jpg",
  },

  "Sofia Hussain": {
    name: "Sofia Hussain",
    designation: "Manager - Human Resources, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/sofia-hussain-a4b83021/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c704c5c03c_sofia_hussain.jpg",
  },

  "Stijn Rijckbosch": {
    name: "Stijn Rijckbosch",
    designation: "Director - Finance, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/stijn-rijckbosch-92860140/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c6fb7ea191_stijn_rijckbosch.jpg",
  },
  "Purvashri Vora": {
    name: "Purvashri Vora",
    designation:
      "Assistant Manager - Marketing, Consumer Products Division, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/purvashrivora/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c7277f38e0_purvashri_vora_2.jpg",
  },

  "Sreenath Nair": {
    name: "Sreenath Nair",
    designation: "Assistant Manager - Marketing, Luxe Division, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/sreenath-nair/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c6ff9a4974_dsc_9410_copy_copy.jpg",
  },

  "Arzoo Dalal": {
    name: "Arzoo Dalal",
    designation:
      "Assistant Manager - Marketing, Consumer Products Division, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/arzoodalal/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c75902b222_img_20191116_144713_02_01.jpg",
  },

  "Udit Arunav": {
    name: "Udit Arunav",
    designation: "Management Trainee - IT, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/uditarunav/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c6f166524f_udit_arunav.jpg",
  },

  "Ashna Ranjan": {
    name: "Ashna Ranjan",
    designation: "Management Trainee - Marketing, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/ashnaranjan13/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c756829258_ashna_ranjan.jpg",
  },

  "Shruti Bajpai": {
    name: "Shruti Bajpai",
    designation: "Management Trainee - Digital, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/shruti-bajpai-474b2099/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c70f0328df_shruti_bajpai.jpg",
  },

  "Aashima Garg": {
    name: "Aashima Garg",
    designation: "Management Trainee - Commercial, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/aashima-garg/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c7766ab75e_aashima_garg.jpg",
  },

  "Chaitanya Ganu": {
    name: "Chaitanya Ganu",
    designation: "Management Trainee - Marketing, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/chaitanya-ganu-951429112/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c74c0f2e9c_chaitanya_ganu.jpg",
  },

  "Varun Agrawal": {
    name: "Varun Agrawal",
    designation: "Management Trainee - Digital, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/varunagrawal1/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c6edad27b5_varun_agrawal.jpg",
  },

  "Amit Jain": {
    name: "Amit Jain",
    designation: "Managing Director, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/amit-jain-1591752/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c76bed8980_amit_jain.jpg",
  },

  "Priya Pandit": {
    name: "Priya Pandit",
    designation: "Assistant General Manager - Human Resources, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/priya-pandit-5a736a16/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c72a0514a0_priya_pandit.jpg",
  },

  "Nandini Bhalla": {
    name: "Nandini Bhalla",
    designation: "Manager, Human Resources, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/nandini-bhalla-hr/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/uploads/images/612c7351afca0_nandini_bhalla.jpg",
  },
  "Nisha Sanghvi": {
    name: "Nisha Sanghvi",
    designation: "Assistant General Manager - Human Resources, L'Oréal India",
    linked_ln_profile: "https://www.linkedin.com/in/nisha-doshi-ba047417/",
    email: "",
    enabled: true,
    profile_image:
      "https://d8it4huxumps7.cloudfront.net/files/6132371772061_nisha_sanghvi.jpeg",
  },
};

class Connect extends Component {
  state = {
    list: connectUserData,
  };

  componentDidMount = () => {
    if (this.props.addAnalytics) {
      this.props.addAnalytics(true);
    }
    // attachConnectProfilesListener((err, data) => {
    //     if (err) {
    //         console.log(data)
    //         return;
    //     }
    //     console.log(data)
    //     this.setState({
    //         list: data
    //     })
    // })
  };

  componentWillUnmount = () => {
    if (this.props.addAnalytics) {
      this.props.addAnalytics(false);
    }
    // removeConnectProfilesListener();
  };

  handleConnectButton = (link) => {
    if (link.includes("http")) {
      window.open(link, "_blank");
      return;
    }
    link = "https://" + link;
    window.open(link, "_blank");
  };

  render() {
    const { list } = this.state;
    return (
      <>
        <div className="connectContainer">
          <div className="connectHeader " style={{ ...HeaderBackground }}>
            <div className="logo-left" style={LogoLeft}></div>

            <div className="header_right">
              {/* <div
                className="connect-button"
                onClick={(e) => this.handleConnectButton(connect_link)}
              >
                {" "}
                Connect{" "}
              </div> */}
              <div
                className="connect-closeButton"
                style={closeButton}
                onClick={(e) => this.props.close(e)}
              ></div>
            </div>
            {/* <div className="logo-right" style={LogoRight}></div> */}
          </div>
          <div className="connectBody">
            {list && (
              <>
                {Object.keys(list)
                  .sort()
                  .map((key) => (
                    <div className="connect-card" key={key}>
                      <div className="connect-card-head ">
                        <div
                          className="connect-card-head-image"
                          style={{
                            background: `url(${list[key].profile_image})`,
                            backgroundPosition: "top",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        <div className="connect-card-head-details">
                          <div className="connect-card-name">
                            {list[key].name}
                          </div>
                          {/* <div className="connect-card-title">
                          {list[key].designation}
                        </div> */}
                          <div className="connect-card-email">
                            {list[key].email}
                          </div>
                          {/* {list[key].linked_ln_profile && (
                          <div
                            className="connect-card-button"
                            onClick={(e) =>
                              this.handleConnectButton(
                                list[key].linked_ln_profile
                              )
                            }
                          >
                            Connect
                          </div>
                        )} */}
                        </div>
                      </div>

                      {/* <div className="heroBorder"></div> */}
                      <div className="connect-card-body">
                        <p>{list[key].designation}</p>
                        {list[key].linked_ln_profile && (
                          <div
                            className="connect-card-button"
                            onClick={(e) =>
                              this.handleConnectButton(
                                list[key].linked_ln_profile
                              )
                            }
                          >
                            Connect
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Connect;
